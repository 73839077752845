html {
  font-size: 17px !important;
}

input, textarea {
  outline: none !important;
}

input:active, textarea:active {
  outline: none !important;
}

:focus {
  outline: none !important;
}

@font-face {
  font-family: "Arial-Regular";
  src: url('/assets/fonts/Arial-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Arial-Bold";
  src: url('/assets/fonts/Arial-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url('/assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-Regular";
  src: url('/assets/fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-Bold";
  src: url('/assets/fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Arial-Regular", sans-serif;
  color: white;
}

.preview_background {
  background: url(/assets/img/img_bg.png) no-repeat center;
  background-size: cover;
  width: 100%;
  height: 693px;
  min-height: 560px;
}

.preview_background_content {
  width: 1050px;
  margin: 0px auto;
  padding: 34px 0 0;
}

.logo_box {
  background: url(/assets/img/logo.svg) no-repeat center;
  background-size: cover;
  width: 110px;
  height: 31px;
}

.preview {
  display: flex;
  justify-content: space-between;
  margin-top: 110px;
}

.left_preview {
  width: 590px;
  margin-top: 30px;
}

.left_preview_title {
  font-family: "Arial-Bold", sans-serif;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 16px;
}

.left_preview_mini_title {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #ffffffb3;
  margin-bottom: 40px;
}

.left_preview_items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 480px;
}

.item_left_preview {
  font-family: "OpenSans-Regular", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  color: #ffffff;
  margin-bottom: 15px;
  width: 240px;
}

.right_preview {
  width: 410px;
}

.right_preview_address {
  background: #ffffff;
  border-radius: 5px;
  margin-bottom: 8px;
}

.item_address {
  display: flex;
  align-items: center;
  padding: 14px;
}

.item_address:not(:last-child) {
  border-bottom: 1px solid silver;
}

.icon_address {
  width: 17px;
  height: 16px;
  margin-right: 12px;
  display: flex;
  text-align: center;
}

.icon1 {
  background: url(/assets/img/icon1.svg) no-repeat center;
  background-size: cover;
}

.icon2 {
  background: url(/assets/img/icon2.svg) no-repeat center;
  background-size: cover;
}

.input_adress_preview .autocomplete-container .input-container input {
  color: black;
  outline: none;
  border: 1px solid white;
  background: white;
  width: 100%;
  font-family: "Arial-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  box-sizing: content-box;
  padding: 0;
  line-height: normal;
  height: 16px;
}

.input_adress_preview .autocomplete-container {
  box-shadow: none;
  height: 16px;
}

.vlbc_address .autocomplete-container {
  box-shadow: none;
}

.input_adress_preview .autocomplete-container .not-found, .vlbc_address .autocomplete-container .not-found {
  color: black;
}

.item_address address-field {
  flex-grow: 2;
}

.right_preview_tariff {
  background: #ffffff;
  color: black;
  border-radius: 5px;
}

.tariff_folded {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
}

.left_tariff_folded {
  display: flex;
  align-items: center;
}

.icon_ltf1 {
  background: url(/assets/img/car_bus1.svg) no-repeat center;
  background-size: cover;
  width: 20px;
  height: 10px;
  margin-right: 16px;
}

.title_ltf {
  color: black;
  font-family: "Arial-Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-right: 8px;
}

.text_ltf {
  color: #8F8F8F;
  font-family: "Arial-Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-right: 8px;
}

.right_tariff_folded {
  display: flex;
  align-items: center;
}

.prices_rtf {
  font-family: "Arial-Bold", sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin-right: 5px;
}

.time_rtf {
  font-family: "Arial-Regular", sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-right: 20px;
  color: #8F8F8F;
}


.box_info_tariff_folded_open {
  display: none;
}

.tariff_folded_open_active .box_info_tariff_folded_open {
  display: block;
}


.icon_rtf {
  background: url(/assets/img/arrow_index2.svg) no-repeat center;
  background-size: cover;
  width: 10px;
  height: 6px;
}

.tariff_folded_open_active .icon_rtf {
  background: url(/assets/img/arrow_index1.svg) no-repeat center;
  background-size: cover;
  width: 10px;
  height: 6px;
}

.bitfo_active {
  display: block;
}

.tariff_folded_open {
  border-radius:5px;
}

.tariff_folded_open_active {
  background: #FAFBFC;
}


.tariff_folded_open_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
  cursor: pointer;
}

.tariff_folded_open_active .tariff_folded_open_top {
  cursor: auto;
}

.icon_ltf2 {
  background: url(/assets/img/car_bus2.svg) no-repeat center;
  background-size: cover;
  width: 22px;
  height: 12px;
  margin-right: 16px;
}

.icon_rtf2 {
  background: url(/assets/img/Mask2.png) no-repeat center;
  background-size: cover;
  width: 10px;
  height: 6px;
}

.tariff_folded_open_info {
  display: flex;
  justify-content: space-between;
  padding: 0 14px;
  margin-bottom: 5px;
}

.tfpi_photo {
  background: url(/assets/img/img.png) no-repeat center;
  background-size: cover;
  width: 150px;
  height: 64px;
}

.tfpi_box {
  width: 212px;
}

.tfpi_box_bold {
  font-family: "OpenSans-Regular", sans-serif;
  font-weight: 400;
  font-size: 11px;
  Line-height: 15px;
  margin-bottom: 4px;
  color: #323c5dd9;
}

.tfpi_box_regular {
  font-family: "OpenSans-Regular", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: #323c5d80;
}

.tariff_folded_open_midlle {
  display: flex;
  padding: 0 22px;
}

.tfom_box {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.tbi_icon1 {
  background: url(/assets/img/contur_box1.svg) no-repeat center;
  background-size: cover;
  width: 12px;
  height: 12px;
  margin-right: 6px;
}

.tbi_icon2 {
  background: url(/assets/img/contur_box2.svg) no-repeat center;
  background-size: cover;
  width: 12px;
  height: 12px;
  margin-right: 6px;
}

.tfom_box_text {
  color: #323c5db3;
  font-size: 10px;
  font-family: "OpenSans-Regular", sans-serif;
  font-weight: 400;
  line-height: 13px;
}

.tariff_folded_open_bottom {
  padding: 14px 14px 14px 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tfob_box_time {
  display: flex;
  align-items: center;
}

.tfob_box_time_icon {
  background: url(/assets/img/time.svg) no-repeat center;
  background-size: cover;
  width: 22px;
  height: 22px;
  margin-right: 9px;
}

.tfob_box_time_text {
  color: #323c5db3;
  font-size: 10px;
  font-family: "OpenSans-Regular", sans-serif;
  font-weight: 400;
  line-height: 14px;
}

.tfob_box_btn {
  line-height: 29px;
  font-size: 20px;
  color: #FFFFFF;
  padding: 10px 18px;
  background: #00AAFF;
  border-radius: 5px;
  width: 176px;
  text-align: center;
  text-decoration: none;
}

.tfob_box_btn:hover {
  cursor: pointer;
  background: #009CF0;
}

.icon_ltf3 {
  background: url(/assets/img/car_bus3.svg) no-repeat center;
  background-size: cover;
  width: 22px;
  height: 12px;
  margin-right: 16px;
}

.icon_rtf3 {
  background: url(/assets/img/Mask.png) no-repeat center;
  background-size: cover;
  width: 10px;
  height: 6px;
}

.bottom_preview {
  padding: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 62px;
}

.bottom_preview_text {
  font-family: "Arial-Bold", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.bottom_preview_icon {
  background: url(/assets/img/mover.svg) no-repeat center;
  background-size: cover;
  width: 94px;
  height: 10px;
  margin-left: 9px;
}

.advantages {
  padding: 56px 0;
}

.advantages_content {
  width: 1050px;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item_advantages {
  width: calc(33% - 70px);
}

.item_advantages_icon {
  margin: 0px auto;
  margin-bottom: 16px;
}

.advantages_icon1 {
  background: url(/assets/img/application_icon1.svg) no-repeat center;
  background-size: contain;
  width: 88px;
  height: 96px;
}

.advantages_icon2 {
  background: url(/assets/img/application_icon2.svg) no-repeat center;
  background-size: cover;
  width: 88px;
  height: 96px;
}

.advantages_icon3 {
  background: url(/assets/img/application_icon3.svg) no-repeat center;
  background-size: cover;
  width: 88px;
  height: 96px;
}

.item_advantages_title {
  color: black;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  font-family: "Arial-Bold", sans-serif;
  margin-bottom: 7px;
}

.item_advantages_text {
  color: black;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Arial-Regular", sans-serif;
}

.question {
  padding: 35px;
  color: black;
}

.question_content {
  width: 830px;
  margin: 0px auto;
}

.question_title {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 46px;
  font-family: "Arial-Bold", sans-serif;
  margin-bottom: 6px;
}

.question_mini_title {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Arial-Regular", sans-serif;
  margin-bottom: 48px;
}

.head_question_box {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.text_question_box {
  display: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Arial-Regular", sans-serif;
  margin-left: 25px;
  padding: 5px;
}

.question_box_active .text_question_box {
  display: block;
}

.text_question_box p {
  margin: 0 0 10px 0;
}

.question_box_arrow {
  background: url(/assets/img/arrow_question.svg) no-repeat center;
  background-size: cover;
  width: 6px;
  height: 10px;
  margin-right: 19px;
  transform: rotate(0deg);
  transition: transform 150ms ease;
}

.question_box_active .question_box_arrow {
  transform: rotate(90deg);
  transition: transform 150ms ease;
}

.question_box_text {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  font-family: "Arial-Bold", sans-serif;
  padding: 5px;
}

.question_box_mini_text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Arial-Regular", sans-serif;
  margin-left: 25px;
  padding: 5px;
}

.footer {
  padding: 35px 35px 0;
  color: black;
  background: #FAFBFC;
  border-top: 1px solid silver;
}

.footer_content {
  width: 1050px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
}

.logo_footer_box {
  width: calc(50% - 15px);
}

.logo_footer {
  background: url(/assets/img/logo_black.svg) no-repeat center;
  background-size: cover;
  width: 160px;
  height: 45px;
}

.box_footer {
  width: calc(50% - 15px);
}

.contacts_top_box_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text_top_box_footer {
  font-size: 13px;
  color: silver;
  font-weight: 700;
  font-family: "Arial-Bold", sans-serif;
}

.phone_top_box_footer {
  font-size: 20px;
  color: black;
  font-weight: 700;
  font-family: "Arial-Bold", sans-serif;
  padding: 8px 0;
  line-height: 28px;
}

.vector_top_box_footer {
  background: url(/assets/img/mover_logo.svg) no-repeat center;
  background-size: cover;
  width: 32px;
  height: 28px;
  margin-left: 45px;
}

.btn_top_box_footer {
  background: white;
  padding: 10px 18px;
  border: 1px solid silver;
  font-size: 14px;
  height: fit-content;
}

.btn_top_box_footer:hover {
  cursor: pointer;
  background: #f1f1f1;
}

.midlle_box_footer {
  border-bottom: 3px solid #d8d8d8;
}

.phone_top_box_footer:not(:first-child) {
  padding-bottom: 35px;
}

.bottom_box_footer {
  padding: 25px 0;
  display: flex;
  justify-content: space-between;
}

.bottom_box_footer_text {
  font-size: 13px;
  color: silver;
  font-weight: 700;
  font-family: "Arial-Bold", sans-serif;
  width: calc(31% - 15px);
  line-height: 20px;
}

.bottom_box_footer_text a, .bottom_box_footer_text_long a {
  color: inherit;
  text-decoration: none;
}

.bottom_box_footer_text_long {
  font-size: 13px;
  color: silver;
  font-weight: 700;
  font-family: "Arial-Bold", sans-serif;
  width: calc(38% - 15px);
  line-height: 20px;
}

.header {
  padding: 6px 0;
  background: #292929;
}

.header_content {
  width: 1250px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_menu_left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_menu_box {
  display: flex;
}

.header_menu_btn {
  margin-right: 20px;
  font-size: 14px;
  font-family: "Arial-Regular", sans-serif;
  font-weight: 400;
  line-height: 18px;
}

.header_menu_btn:hover {
  color: #ff6163;
  cursor: pointer;
}

.header_menu_right {
  display: flex;
  align-items: center;
}

.application_icon1 {
  background: url(/assets/img/index_icon_advantages.svg) no-repeat center;
  background-size: cover;
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.application_icon2 {
  background: url(/assets/img/index_icon_advantages2.svg) no-repeat center;
  background-size: cover;
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.application_icon3 {
  background: url(/assets/img/index_icon_advantages3.svg) no-repeat center;
  background-size: cover;
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.application_icon4 {
  background: url(/assets/img/index_icon_advantages4.svg) no-repeat center;
  background-size: cover;
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.header_menu_right_text {
  margin-right: 20px;
  font-size: 14px;
  font-family: "Arial-Regular", sans-serif;
  font-weight: 400;
  line-height: 18px;
}

.header_menu_right_text:hover {
  cursor: pointer;
  color: #ff6163;
}

.header_menu_right_photo {
  background: url(/assets/img/Oval.png) no-repeat center;
  background-size: cover;
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.header_menu_right_name {
  margin-right: 4px;
  font-size: 14px;
  font-family: "Arial-Regular", sans-serif;
  font-weight: 400;
  line-height: 18px;
}

.header_menu_right_arrow {
  background: url(/assets/img/Path-1906.png) no-repeat center;
  background-size: cover;
  width: 8px;
  height: 8px;
  margin: 4px 26px 0 2px;
}

.header_menu_right_btn {
  font-size: 14px;
  font-family: "Arial-Regular", sans-serif;
  font-weight: 400;
  line-height: 18px;
  align-items: center;
  padding: 6px 13px 8px 12px;
  background: #00AAFF;
  text-align: center;
  border-radius: 6px;
}

.header_menu_right_btn:hover {
  background: #009CF0;
  cursor: pointer;
}

.bottom_header {
  padding: 17px 0 5px;
  color: black;
}

.bottom_header_content {
  width: 1250px;
  margin: 0px auto;
  display: flex;
}

.bottom_header_content_mobil {
  display: none;
}

.logo_box_bottom_header {
  background: url(/assets/img/logo_black.svg) no-repeat center;
  background-size: cover;
  width: 110px;
  height: 31px;
}

.menu_box_bottom_header {
  display: flex;
  align-items: flex-end;
}

.item_mbbh {
  margin-left: 22px;
  font-size: 14px;
  font-family: "Arial-Regular", sans-serif;
  font-weight: 400;
  line-height: 18px;
}

.item_mbbh:hover {
  color: #ff6163;
  cursor: pointer;
}

.application {
  padding: 64px 0 0;
  color: black;
  position: relative;
}

.application_content {
  width: 1250px;
  margin: 0px auto;
}

.left_block_application {
  width: 723px;
  display: inline-block;
  float: left;
}

.right_block_application {
  width: 405px;
}

.left_block_application_title {
  width: 100%;
  font-size: 32px;
  font-family: "Arial-Bold", sans-serif;
  font-weight: 700;
  line-height: 46px;
  margin-bottom: 0;
}

.lba_mini_title {
  width: 100%;
  font-size: 20px;
  font-family: "Arial-Bold", sans-serif;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 32px;
}

.lba_box_inputs {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  justify-content: space-between;
}

.lba_text {
  width: 156px;
  font-size: 16px;
  font-family: "Arial-Regular", sans-serif;
  font-weight: 400;
  line-height: 24px;
}

.lba_input {
  padding: 6px 10px;
  border-radius: 4px;
  border: 1px solid silver;
  font-size: 16px;
  font-family: "Arial-Regular", sans-serif;
  font-weight: 400;
  line-height: 24px;
  width: calc(50% - 10px);
}

.lba_address_item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 567px;
}

.lba_address_box {
  background: #F5F5F5;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 10px;
  margin-bottom: 5px;
}

.lba_address_box_close {
  background: url(/assets/img/Close.png) no-repeat center;
  background-size: cover;
  width: 11px;
  height: 11px;
}

.lba_address_box_text {
  font-size: 16px;
  font-family: "Arial-Regular", sans-serif;
  font-weight: 400;
  line-height: 24px;
}

.lba_info_adress {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.lba_info_adress_text {
  font-size: 14px;
  font-family: "Arial-Regular", sans-serif;
  font-weight: 400;
  line-height: 20px;
  color: #8F8F8F;
  margin-right: 5px;
}

.lba_info_adress_question {
  background: url(/assets/img/mini_question.svg) no-repeat center;
  background-size: cover;
  width: 16px;
  height: 16px;
}

.lba_box_inputs_wrap {
  margin-left: 156px;
  width: 567px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.lba_box_inputs_wrap_item {
  width: 50%;

}

.lba_box_inputs_wrap_input {
  width: 263px;
  padding: 8px 10px;
  border-radius: 6px;
  border: 1px solid silver;
  font-size: 16px;
  font-family: "Arial-Regular", sans-serif;
  font-weight: 400;
  line-height: 24px;
}

.success_content {
  width: 1250px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 172px);
}

.right_success_content_photo {
  background: url(/assets/img/success_icon.svg) no-repeat center;
  background-size: cover;
  width: 405px;
  height: 384px;
}

.left_success_content {
  color: black;
  margin-top: 83px;
}

.left_success_content_title {
  font-size: 32px;
  font-family: "Arial-Bold", sans-serif;
  font-weight: 700;
  line-height: 46px;
  margin-bottom: 8px;
}

.left_success_content_title_mobil {
  display: none;
}

.left_success_content_mini_title {
  font-size: 16px;
  font-family: "Arial-Regular", sans-serif;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 28px;
}

.left_success_content_mini_title_mobil {
  display: none;
}

.left_success_content_text {
  font-size: 16px;
  font-family: "Arial-Regular", sans-serif;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 28px;
  width: 512px;
}

.hidden_web {
  display: block;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
}

.lba_box_content.hidden_web, .title_lba_box_content.hidden_web {
  display: none;
}

.mobil_cart {
  display: none;
}

.left_success_content_btn {
  font-size: 16px;
  font-family: "Arial-Regular", sans-serif;
  font-weight: 400;
  line-height: 22px;
  width: fit-content;
  background: #00AAFF;
  padding: 10px 16px;
  text-align: center;
  border-radius: 3px;
  color: white;
}

.left_success_content_btn:hover {
  cursor: pointer;
  background: #009CF0;
}

.footer_mini {
  padding: 10px 0;
  border-bottom: 2px solid #E0E0E0;
  background: #FAFAFA;
  color: #007DEB;
}

.footer_mini_content {
  width: 960px;
  margin: 0px auto;
}

.top_mini_footer {
  display: flex;
  justify-content: space-between;
}

.top_mini_footer_menu {
  font-size: 11px;
  font-family: "Arial-Regular", sans-serif;
  font-weight: 400;
  line-height: 16px;
}

.top_mini_footer_menu:hover {
  color: #ff6163;
  cursor: pointer;
}

.top_mini_footer_menu_bold {
  font-size: 11px;
  font-family: "Arial-Bold", sans-serif;
  font-weight: 700;
  line-height: 16px;
}

.top_mini_footer_menu_bold:hover {
  color: #ff6163;
  cursor: pointer;
}

.bottom_mini_footer {
  padding: 10px 0;
}

.bottom_mini_footer_content {
  width: 960px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
}

.bottom_mini_footer_item {
  color: #8F8F8F;
  font-size: 11px;
  font-family: "Arial-Regular", sans-serif;
  font-weight: 400;
  line-height: 16px;
}

.bottom_mini_footer_href {
  color: #8F8F8F;
  font-size: 11px;
  font-family: "Arial-Regular", sans-serif;
  font-weight: 400;
  line-height: 16px;
  text-decoration: none;
  border-bottom: 1px solid #8F8F8F;
}

.lba_box_content {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.lba_box_content_mobil {
  display: none;
}

.title_lba_box_content {
  width: 150px;
  height: fit-content;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-top: 8px;
}

.title_lba_box_content.removable {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.title_lba_box_content.removable .confirm_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title_lba_box_content.removable .confirm {
  font-size: 13px;
  font-family: "Arial-Regular", sans-serif;
  font-weight: 400;
  color: #ff6163;
  position: relative;
  overflow: hidden;
  order: 1;
  margin: 0 5px;
}

.title_lba_box_content.removable img.remove {
  order: 0;
}

.title_lba_box_content.removable .confirm > div {
  position: relative;
}

.values_lba_box_content {
  width: calc(100% - 165px);
  display: flex;
  /*overflow: hidden;*/
  flex-wrap: wrap;
  justify-content: space-between;
}

.values_lba_box_content.cargo_block {
  align-items: flex-start;
}

.margin_values {
  margin-bottom: 0px;
}

.time_lba_box_content {
  display: flex;
}

.date_time_input {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.date_time_input input {
  width: 100%;
  appearance: none;
}

.box_big_input_vlbc {
  width: 100%;
  margin-bottom: 20px;
}

.date_time_input .input_vlbc_data {
  width: calc(50% - 5px);
  display: inline-block;
}


.checkbox_big_input_vlbc {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  margin-top: -15px;
}

.checkbox_input_vlbc {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.input_vlbc_checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.input_vlbc_checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.input_vlbc_checkbox + label::before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid #adb5bd;
  border-radius: 3px;
  margin-right: 16px;
  cursor: pointer;
}

.input_vlbc_checkbox:checked + label::before {
  border-color: #00AAFF;
  background-color: #00AAFF;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5L4 8L11 1' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% auto;
}

.input_vlbc_checkbox:not(:disabled):not(:checked) + label:hover::before {
  border-color: #00AAFF;
  cursor: pointer;
}

.autocomplete-container .input-container input:disabled {
  background-color: #f5f5f5 !important;
  color: #000000de !important;
}

.input_vlbc_checkbox_text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 8px;
}

.input_vlbc_data input {
  height: 40px;
  border: 1px solid #E8E8E8;
  padding: 6px 10px;
  border-radius: 4px;
  margin-right: 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.box_mini_input_vlbc_mobil {
  display: none;
}

.bmiv_mobil {
  font-size: 16px;
  line-height: 22px;
  color: #00AAFF;
  margin-bottom: 20px;
}

.bmiv_mobil:hover {
  color: #ff6163;
  cursor: pointer;
}

.box_mini_input_vlbc {
  width: calc(50% - 10px);
  margin-bottom: 20px;
  position: relative;
  font-size: 15px;
}

.box_mini_input_vlbc.with_select::after {
  content: "⌄";
  font-size: 22px;
  top: 6px;
  right: 10px;
  position: absolute;
}

.box_mini_input_vlbc.with_select select {
  appearance: none;
  padding-right: 30px;
}

.input_vlbc,
.input_vlbc_contact_phone input,
.vlbc_address .autocomplete-container .input-container input {
  width: 100%;
  height: 40px;
  border: 1px solid #E8E8E8;
  padding: 7px 11px;
  border-radius: 4px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  background-color: white;
}

.vlbc_address.no-border .autocomplete-container .input-container input {
  border: none;
}

.vlbc_address {
  border: none;
}

.text_input_vlbc {
  font-size: 13px;
  font-family: "Arial-Regular", sans-serif;
  font-weight: 400;
  line-height: 20px;
  color: #8F8F8F;
}

.item_title_lba_box_content {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.mini_item_title_lba_box_content {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #8F8F8F;
  margin-top: 8px;
  width: 145px;
  display: flex;
}

.mitlbc_icon {
  background: url(/assets/img/time_car_icon.svg) no-repeat center;
  background-size: cover;
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.mitlbc_text {
  width: 130px;
}

.mitlbc_text a {
  color: inherit;
}

.box_big_item_vlbc {
  width: 100%;
  border: 1px solid #DFE6F4;
  box-sizing: border-box;
  border-radius: 12px;
  margin-bottom: 15px;
  padding: 16px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

.bbiv_active {
  width: 100%;
  border: 2px solid #00AAFF;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05), 0px 4px 24px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  margin-bottom: 15px;
  padding: 16px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.box_big_item_vlbc:hover {
  background: #FAFBFC;
  cursor: pointer;
}

.bbiv_active:hover {
  /*border: 1px solid #DFE6F4;*/
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05), 0px 4px 24px rgba(0, 0, 0, 0.12);
  background: white;
  cursor: default;
}


.item_tbbiv_checkbox_hidden {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.item_tbbiv_checkbox {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #D6D6D6;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item_tbbiv_checkbox:before {
  content: '';
  background: white;
  border: 1px solid white;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}


.bbiv_active .item_tbbiv_checkbox {
  background: #00AAFF;
  border: 1px solid #00AAFF;
}

.bbiv_active .item_tbbiv_checkbox:before {
  background: white;
  border: 1px solid white;
}

.cost_info {
  display: none;
}

.tbbiv_left {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.tbbiv_right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 284px;
}

.item_tbbiv_title {
  font-family: "OpenSans-Bold", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #323C5D;
}

.item_tbbiv_icon {
  background: url(/assets/img/car_box1.svg) no-repeat center;
  background-size: cover;
  width: 12px;
  height: 12px;
  margin-right: 25px;
}

.item_tbbiv {
  display: flex;
  border-radius: 50%;
}

.item_tbbiv_left {
  display: flex;
  border-radius: 10px 0 0 10px;
  border: 1px solid #E8E8E8;
  align-items: center;
  padding: 8px 10px;
}

.item_tbbiv_right {
  display: flex;
  border-radius: 0px 10px 10px 0px;
  border: 1px solid #E8E8E8;
  align-items: center;
  padding: 8px 10px;
}

.tbbiv_icon1 {
  background: url(/assets/img/car_box2.svg) no-repeat center;
  background-size: cover;
  width: 16px;
  height: 16px;
  margin-right: 7px;
}

.tbbiv_icon2 {
  background: url(/assets/img/car_box3.svg) no-repeat center;
  background-size: cover;
  width: 16px;
  height: 16px;
  margin-right: 7px;
}

.item_tbbiv_left_text {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
}

.item_bbbiv_photo {
  background: url(/assets/img/mini_van_normal.png) no-repeat center;
  width: 236px;
  height: 100px;
  background-size: 220px auto;
}

.item_bbbiv_info {
  width: 284px;
  margin-top: 15px;
}

.item_bbbiv_info_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 191px;
}

.item_bbbiv_info_size {
  width: calc(50% - 5px);
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.item_bbbiv_info_name {
  font-family: "Arial-Regular", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #8F8F8F;
  opacity: 0.7;
  margin-bottom: 15px;
  min-height: 55px;
}

.item_bbbiv_info_size_text {
  font-family: "OpenSans-Regular", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: #323c5db3;
  white-space: nowrap;
}

.bbbiv_icon1 {
  background: url(/assets/img/car_box4.svg) no-repeat center;
  background-size: cover;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.bbbiv_icon2 {
  background: url(/assets/img/car_box5.svg) no-repeat center;
  background-size: cover;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.bbbiv_icon3 {
  background: url(/assets/img/car_box6.svg) no-repeat center;
  background-size: cover;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.bbbiv_icon4 {
  background: url(/assets/img/car_box7.svg) no-repeat center;
  background-size: cover;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.box_big_checkbox_vlbc {
  width: 100%;
  display: flex;
  margin: 6px 0 17px;
}

.item_bbcv_checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.item_bbcv_checkbox + label {
  display: inline-flex;
  user-select: none;
}

.item_bbcv_checkbox + label::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  border: 8px solid #EBEBEB;
  border-radius: 50%;
  margin-right: 15px;
  cursor: pointer;
  background: #EBEBEB;
}

.item_bbcv_checkbox:checked + label::before {
  border-color: #00AAFF;
  background-color: white;
}

.item_bbcv_box_text {
  font-family: "Arial-Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 2px;
}

.item_bbcv_box_mini_text {
  font-family: "Arial-Regular", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: #858585;
}

.item_bbcv_box_mini_text > br {
  display: none;
}

.box_btns_vlbc {
  width: 100%;
  overflow: hidden;
}

.content_box_btns_vlbc {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: calc(100% + 15px);
}

.btn_vlbc {
  padding: 12px 21px;
  background: #F5F5F5;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  align-self: flex-start;
}

.content_box_btns_vlbc .with_subcontrol .subcontrol {
  padding: 11px 20px;
  background: white;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  align-self: flex-start;
  border: 1px solid black;
  cursor: pointer;
}

.content_box_btns_vlbc .with_subcontrol .subcontrol img {
  vertical-align: text-top;
}

.content_box_btns_vlbc .with_subcontrol .subcontrol .add_card {
  cursor: pointer;
}

.card-controls .error {
  font-size: 80%;
  color: red;
  margin-bottom: 20px;
}

.btn_vlbc:hover {
  cursor: pointer;
  background: #eaeaea;
}

.btn_vlbc_active {
  background: #00AAFF;
  color: white;
}

.btn_vlbc_active:hover {
  background: #009CF0;
  color: white;
}

.values_lba_box_info {
  width: calc(100% - 165px);
}

.values_lba_box_textarea {
  width: 100%;
  height: 160px;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 8px 12px;
  resize: none;
  font-size: 16px;
  line-height: 24px;
  font-family: "Arial-Regular", sans-serif;
}

.values_lba_box_btn {
  font-family: "Arial-Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #00AAFF;
  margin: 12px 0 12px;
}

.values_lba_box_btn:hover {
  cursor: pointer;
  color: #ff6163;
}

.input_vlbc_name {
  width: 345px;
  height: 40px;
  border: 1px solid #E8E8E8;
  padding: 6px 10px;
  border-radius: 4px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.input_vlbc_phone input {
  width: 170px;
  height: 40px;
  border: 1px solid #E8E8E8;
  padding: 6px 10px;
  border-radius: 4px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.accept_phone_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.accept_phone_container .values_lba_box_btn {
  min-width: 160px;
}

.code_container {
  width: 70px;
}

.accept_code input {
  /*font-size: 150%;*/
  font-family: monospace, sans-serif;
  letter-spacing: 4px;
  width: 100%;
  margin: 12px 0 12px 20px;
  border: 1px solid #CCCCCC;
  padding: 5px 0 5px 7px;
  border-radius: 3px;
  text-align: center;
}

.invisible {
  visibility: hidden;
}

.values_lba_box_btn.accept_status {
  color: #CCCCCC;
}

.values_lba_box_contacts {
  width: calc(100% - 165px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 16px;
}

.box_contacts_input_vlbc {
  width: 100%;
}

.left_block_application_footer {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #8F8F8F;
  margin: 32px 0 45px;
}

.left_block_application_footer a, .left_block_application_footer_mobil a {
  color: inherit;
}

.left_block_application_footer_href {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #8F8F8F;
  margin: 32px 0 45px;
}

.right_block_application_box {
  padding: 20px 24px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05), 0px 4px 24px rgba(0, 0, 0, 0.12);
  margin-bottom: 40px;
  height: fit-content;
  display: inline-block;
  float: right;
  width: 405px;
  position: sticky;
  top: 25px;
  background-color: white;
}

.right_block_application_items {
  display: inline-block;
  float: right;
  width: 445px;
}

.item_rbab_sum {
  display: flex;
  justify-content: space-between;
}

.item_rbab {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  padding: 4px 0;
}

.item_rbab.total {
  border-bottom: none;
}

.item_rbab_res_text {
  font-family: 'Arial-Regular', sans-serif;
  font-size: 20px;
  line-height: 28px;
}

.item_rbab_res_sum {
  font-family: Arial-Bold;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

.item_rbab_text_bold {
  font-family: Arial-Regular;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.bolder {
  font-family: Arial-Bold;
}

.item_rbab_text_regular {
  font-family: Arial-Regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #858585;
}

.item_rbab_sum {
  font-family: Arial-Regular;
  font-size: 16px;
  line-height: 20px;
}

.item_rbab_btn {
  padding: 8px 16px;
  background: #0099F7;
  border-radius: 3px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  width: calc(100% - 32px);
  color: #FFFFFF;
  margin-top: 20px;
}

.item_rbab_btn:hover {
  background: #009cf0;
  cursor: pointer;
}


.item_rbab_text {
  margin-top: 15px;
  font-size: 14px;
  line-height: 20px;
  color: #8F8F8F;
}

.item_rbai_icon_box {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.item_rbai_icon.font_icon {
  font-size: 25px;
  width: 30px;
  height: 30px;
  margin-top: 20px;
}

.item_rbai_title {
  display: none;
}

.item_rbai_mobil {
  display: none;
}

.item_rbai_icon_box {
  background: url(/assets/img/mover_black.svg) no-repeat center;
  background-size: cover;
  width: 119px;
  height: 28px;
}

.item_rbai_text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
  width: 300px;
}

.rbai_icon3 {
  background: url(/assets/img/wrenchman.png) no-repeat center;
  background-size: cover;
  width: 30px;
  height: 30px;
  margin-top: 20px;
}

.rbai_icon4 {
  background: url(/assets/img/quality.png) no-repeat center;
  background-size: cover;
  width: 30px;
  height: 30px;
  margin-top: 20px;
}

.rbai_icon5 {
  background: url(/assets/img/angel.png) no-repeat center;
  background-size: cover;
  width: 30px;
  height: 30px;
  margin-top: 20px;
}

.rbai_icon6 {
  background: url(/assets/img/umbrella.png) no-repeat center;
  background-size: cover;
  width: 30px;
  height: 30px;
  margin-top: 20px;
}

.item_rbai_mini_text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #858585;
  width: 300px;
}

.left_block_application_footer_mobil {
  display: none;
}

.left_preview_btn_mobil {
  display: none;
}

.advantages_mobil {
  display: none;
}

.mobil_footer {
  display: none;
}

.item_rbai_mobil_box {
  display: none;
}

.box_big_item_vlbc_btn_mobil {
  display: none;
}

.item_bbbiv_info_box_mobil {
  display: none;
}

.item_bbbiv_info_box_mobil_prices {
  display: none;
}

.item_bbbiv_info_box_mobil_prices.additional-price {
  font-size: 12px;
  color: #8F8F8F;
  font-family: "Arial-Regular", sans-serif;
  font-weight: 400;
  opacity: 0.7;
}

.terms-of-use, .privacy-policy, .terms-and-definitions {
  color: black;
  margin: 3em 2em 0 2em;
}

.terms-of-use p:first-of-type, .privacy-policy p:first-of-type {
  border-left: 5px solid #323c5d;
  font-size: 14px;
  line-height: 1.4;
  color: #323c5d;
  padding-left: 35px;
}

.address_controls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.masked {
  position: relative;
}

.masked .mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loaders_content {
  margin-top: 16px;
}

.sizes_short {
  display: none;
}

@media (max-width: 1370px) {

  .header_content {
    width: 1180px;
  }

  .bottom_header_content {
    width: 1180px;
  }

  .application_content {
    width: 1180px;
  }

  .right_block_application_box {
    width: 365px;
  }

  .right_block_application_items {
    width: 413px;
  }

  .success_content {
    width: 1180px;
  }

  .vlbc_address {
    width: 100%;
  }
}

@media (max-width: 1200px) {

  .header_content {
    width: 900px;
  }

  .display_mobil {
    display: block !important;
  }

  .mobil_cart {
    display: block;
    position: fixed;
    bottom: 20px;
    right: 16px;
    min-width: 92px;
    padding: 12px 16px;
    gap: 12px;

    background: #00AAFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
  }

  .mobil_cart .link {
    text-decoration: none;
    white-space: nowrap;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #FFFFFF;
    flex: none;
    order: 1;
    flex-grow: 0;
    justify-content: space-between;
  }

  .mobil_cart .link img {
    vertical-align: text-top;
  }

  .bottom_header_content {
    width: 900px;
  }

  .application_content {
    width: 900px;
  }

  .box_big_item_vlbc, .bbiv_active {
    justify-content: flex-start;
    align-content: flex-start;
  }

  .bbiv_active .item_bbbiv_photo {
    background-color: #fdedcb;
  }

  .right_block_application_items {
    width: 100%;
    order: 1;
  }

  .right_block_application_box {
    width: 522px;
    order: 3;
    display: block;
    margin: 0px auto;
    margin-top: 32px;
    margin-bottom: 18px;
  }

  .application_content {
    width: 900px;
    display: flex;
    flex-wrap: wrap;
  }

  .left_block_application {
    order: 2;
  }

  .item_rbai_text {
    width: 100%;
    margin-top: 0;
  }

  .item_rbai_mini_text {
    width: 100%;
  }

  .item_rbai_mobil_box {
    display: flex;
    align-items: center;
    margin-top: 40px;
  }

  .item_rbai_mobil_box_icon {
    background:url(/assets/img/arrow_mobil.svg)no-repeat center;
    background-size:cover;
    width:20px;
    height:20px;
    margin-right:10px;
    transform: rotate(90deg);
    transition: transform 150ms ease;
  }

  .opened .item_rbai_mobil_box_icon {
    transform: rotate(180deg);
    transition: transform 150ms ease;
  }

  .item_rbai_mobil_box_text {
    font-family: "Arial-Bold", sans-serif;
    font-size: 16px;
    line-height: 22px;
  }

  .item_rbai_mobil {
    display: block;
  }

  .item_rbai_big {
    display: none;
  }

  .item_rbai_mobil_box {
    display: flex;
    align-items: center;
    margin-top: 24px;
    position: relative;
    left: -5px;
  }

  .item_rbai_mobil_box_text {
    font-family: "Arial-Bold", sans-serif;
    font-size: 16px;
    line-height: 22px;
  }

  .item_rbai_title {
    display: block;
    font-size: 26px;
    line-height: 29px;
    font-family: "Arial-Bold", sans-serif;
    margin-bottom: 25px;
  }

  .item_rbai {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .rbai_icon3 {
    margin-right: 20px;
  }

  .item_rbai_icon {
    margin-right: 20px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .left_block_application_title {
    padding: 32px 0;
  }

  .item_rbai_icon_box {
    margin-bottom: 16px;
  }

  .item_rbai_vector {
    display: block;
  }

  .rbai_icon1 {
    margin-right: 0;
  }

  .values_lba_box_content {
    width: calc(100% - 165px);
  }

  .left_block_application {
    width: 100%;
  }

  .input_vlbc_data input {
    background: #F5F5F5;
    border: 0px;
  }

  .input_vlbc, .input_vlbc_contact_phone input {
    background: #F5F5F5;
    border: 0px;
    margin-right: 0px;
  }

  .values_lba_box_textarea {
    background: #F5F5F5;
    border: 0px;
  }

  .hidden_mobil {
    display: none;
  }

  .no_wrap_mobil {
    flex-wrap: inherit;
    width: 100%;
  }

  .box_big_item_vlbc {
    width: calc(33% - 15px);
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 5%), 0px 4px 16px rgb(0 0 0 / 8%);
    border-radius: 12px;
    margin-right: 10px;
    padding:0px;
  }

  .tbbiv_left {
    order: 2;
    margin-top: 12px;
    padding-left:12px;
  }

  .tbbiv_right {
    order: 3;
    width: calc(50% - 15px);
    margin: 11px 0 1px;
    padding-right:12px;
  }

  .item_bbbiv_photo {
    width: 100%;
    height: 120px;
    order: 1;
    background-color:#dfe6f4;
    border-radius: 12px 12px 0 0;
  }

  .item_tbbiv_title {
    font-family: "Arial-Bold", sans-serif;
    font-size: 16px;
    line-height: 23px;
    color: #000000;
  }

  .item_tbbiv_icon {
    display: none;
  }

  .item_tbbiv_left {
    padding: 0 10px;
    border: 0;
    border-radius: 0;
  }

  .item_tbbiv_left_icon {
    display: none;
  }

  .item_tbbiv_left_text {
    font-size: 20px;
    line-height: 23px;
    color: #8F8F8F;
  }

  .item_bbbiv_info {
    order: 4;
    margin-top: 4px;
    padding: 0 12px;
  }

  .item_bbbiv_info_name {
    margin-bottom: 6px;
  }

  .item_bbbiv_info_box {
    display: none;
  }

  .item_bbbiv_info_box_mobil {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    color: #8F8F8F;
    margin-bottom: 12px;
  }

  .item_bbbiv_info_box_mobil_prices {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
  }

  .item_bbbiv_info_box_mobil_prices .tier_duration {
    font-size: 16px;
    color: #8F8F8F;
    font-weight: 400;
    font-family: "Arial-Regular", sans-serif;
  }

  .item_bbbivib_mobil_prices_span {
    font-size: 15px;
    line-height: 20px;
    color: #8F8F8F;
    font-weight: 400;
    margin: 12px 0;
  }

  .item_bbbiv_info_box_mobil_prices {
    display: block;
  }

  .box_big_item_vlbc_btn_mobil {
    display: block;
    width: 100%;
    order: 5;
    padding: 3px 16px;
    text-align: center;
    background: #E6F6FF;
    border-radius: 5px;
    font-size: 16px;
    line-height: 22px;
    color: #00AAFF;
    margin: 12px;
  }

  .bbiv_active .box_big_item_vlbc_btn_mobil {
    background-color: #F5F5F5;
    color: #B8B8B8;
  }

  .item_tbbiv_checkbox {
    display: none;
  }

  .item_tbbiv_left_text {
    font-size: 18px;
    line-height: 23px;
    color: #8F8F8F;
    white-space: nowrap;
  }

  .item_tbbiv_right {
    padding: 0 5px;
    border: 0;
    border-radius: 0;
  }

  .hidden_web {
    display: none;
  }

  .input_vlbc_name {
    background: #F5F5F5;
    border: 0px;
    margin-right: 0px;
  }

  .input_vlbc_phone input {
    width: 345px;
    background: #F5F5F5;
    border: 0px;
    margin-right: 0px;
  }

  .left_block_application_footer {
    display: none;
  }

  .left_block_application_footer_mobil {
    margin-bottom: 40px;
    height: fit-content;
    display: inline-block;
    float: right;
    order: 5;
    font-size: 13px;
    line-height: 16px;
    color: #858585;
  }

  .footer_mini_content {
    width: 900px;
  }

  .bottom_mini_footer_content {
    width: 900px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .preview_background_content {
    width: 900px;
  }

  .footer_content {
    width: 830px;
  }

  .advantages_content {
    width: 900px;
  }

  .right_preview {
    width: 370px;
  }

  .logo_footer_box {
    width: calc(30% - 15px);
  }

  .box_footer {
    width: calc(70% - 15px);
  }

  .success_content {
    width: 900px;
  }

  .content_box_btns_vlbc {
    width: calc(100% + 0px);
    margin-bottom: 16px;
  }

  .right_success_content {
    margin-top: 83px;
  }

  .item_bbbiv_photo_active{
    background-color:#dfe6f4;
  }

  .bbiv_active .item_tbbiv_checkbox{
    background: #00AAFF;
    border: 0px solid #00AAFF;
  }

  .bbiv_active .item_tbbiv_checkbox:before{
    background: white;
    border: 0px solid white;
  }

  .item_tbbiv_checkbox_hidden{
    display:none;
  }

  .mobil_toggle.closed .toggle_item {
    display: none;
  }

  .mobil_toggle.opened .toggle_item {
    display: block;
  }

  .mobil_big_title.hidden_mobil {
    display: block;
  }

  .input_vlbc,
  .input_vlbc_contact_phone input,
  .vlbc_address .autocomplete-container .input-container input {
    background-color: #F5F5F5;
  }

  .sizes_short {
    display: block;
    margin-top: 6px;
  }
}

@media (max-width: 930px) {
  .lba_info_adress_question {
    display: none;
  }

  .header_menu_right_icon {
    display: none;
  }

  .header {
    display: none;
  }

  .bottom_header_content {
    width: calc(100% - 30px);
    align-items: center;
  }

  .menu_box_bottom_header {
    display: none;
  }

  .bottom_header_content_mobil {
    display: block;
    background: url(/assets/img/Hamburger_blue.png) no-repeat center;
    background-size: cover;
    width: 30px;
    height: 22px;
    margin-right: 27px;
  }

  .bottom_header_content {
    width: 700px;
  }

  .application_content {
    width: 700px;
  }

  .item_bbbiv_photo {
    width: 100%;
    height: 120px;
    background-color: #dfe6f4;
  }

  .box_big_item_vlbc {
    width: calc(100% - 15px);
    margin: 0 5px 15px 5px;
  }

  .no_wrap_mobil {
    overflow: scroll;
    scroll-behavior: smooth;
  }

  .right_block_application_box {
    margin-top: 32px;
    padding: 0;
    box-shadow: 0 0 0 0;
    margin-bottom: 18px;
    width: 100%;
  }

  .input_vlbc_phone_width {
    width: calc(100% - 165px);
  }

  .input_vlbc_name {
    width: 100%;
  }

  .input_vlbc_phone input {
    width: 100%;
  }

  .footer_mini_content {
    width: 700px;
  }

  .bottom_mini_footer_content {
    width: 700px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .preview_background_content {
    width: 700px;
  }

  .right_preview {
    display: none;
  }

  .preview {
    height: fit-content;
    margin-top: 35px;
  }

  .left_preview_btn_mobil {
    background: #00AAFF;
    border-radius: 5px;
    padding: 12px 16px;
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 15px;
    display: block;
    order: 2;
    width: 100%;
    margin-top: 40px;
  }

  .left_preview_btn_mobil:hover {
    background: #009CF0;
  }

  .left_preview {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .left_preview_items {
    width: 100%;
    order: 1;
  }

  .item_left_preview {
    width: calc(50% - 15px);
  }

  .advantages_content {
    width: calc(100% - 30px);
    display: flex;
    align-items: flex-start
  }

  .item_advantages {
    width: calc(33% - 15px);
    margin-bottom: 56px;
  }

  .question_content {
    width: 100%;
  }

  .footer_content {
    width: 630px;
  }

  .logo_footer_box {
    width: calc(30% - 15px);
  }

  .box_footer {
    width: calc(70% - 15px);
  }

  .logo_footer {
    width: 130px;
    height: 34px;
  }

  .vector_top_box_footer {
    margin-left: 0px;
  }

  .bottom_box_footer_text {
    text-align: center;
    font-size: 12px;
  }

  .bottom_box_footer_text_long {
    text-align: center;
    font-size: 12px;
  }

  .success_content {
    width: 700px;
    margin-bottom: 20px;
  }

  .left_success_content {
    width: calc(50% - 5px);
    margin-top: 20px;
  }

  .right_success_content {
    width: calc(50% - 5px);
  }

  .left_success_content_text {
    width: auto;
  }

  .right_success_content_photo {
    width: 345px;
    height: 345px;
  }

  .left_success_content_btn {
    width: 313px;
  }

  .left_preview_title {
    font-size: 37px;
  }

  .preview_background {
    height: fit-content;
  }

  .advantages {
    padding: 56px 0 0;
  }

  .mobil_big_title {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
  }
}

@media (max-width: 730px) {

  .header {
    display: none;
  }

  .inputs_header {
    margin-top: 14px;
  }

  .menu_box_bottom_header {
    display: none;
  }

  .bottom_header_content_mobil {
    display: block;
    background: url(/assets/img/Hamburger_blue.png) no-repeat center;
    background-size: cover;
    width: 18px;
    height: 14px;
    margin-right: 27px;
  }

  .bottom_header_content {
    width: calc(100% - 30px);
    align-items: center;
  }

  .logo_box_bottom_header {
    width: 72px;
    height: 20px;
  }

  .right_block_application {
    width: 100%;
    order: 1;
    display: grid;
  }

  .left_block_application {
    width: 100%;
    order: 2;
  }

  .application_content {
    width: calc(100% - 30px);
    display: flex;
    flex-wrap: wrap;
  }

  .item_rbai_title {
    display: block;
    font-size: 26px;
    line-height: 29px;
    font-family: "Arial-Bold", sans-serif;
    margin-bottom: 25px;
  }

  .right_block_application_box {
    order: 2;
    margin-top: 32px;
    padding: 0;
    box-shadow: 0 0 0 0;
    margin-bottom: 18px;
  }

  .right_block_application_items {
    order: 1;
  }

  .item_rbai_big {
    display: none;
  }

  .item_rbai_mobil_box {
    display: flex;
    align-items: center;
    margin-top: 24px;
    cursor: pointer;
  }

  .item_rbai_mobil_box_text {
    font-family: "Arial-Bold", sans-serif;
    font-size: 16px;
    line-height: 22px;
  }

  .bottom_mini_footer {
    display: none;
  }

  .footer_mini {
    display: none;
  }

  .left_block_application_title {
    display: none;
  }

  .lba_box_content {
    display: block;
  }

  .title_lba_box_content.removable {
    flex-direction: row;
    align-items: center;
  }

  .title_lba_box_content {
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    font-family: "Arial-Bold", sans-serif;
    margin-bottom: 10px;
  }

  .box_big_input_vlbc {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .input_vlbc_name, .input_vlbc_phone input {
    height: 48px;
    padding: 12px 14px;
  }

  .input_vlbc_data input {
    height: 48px;
    width: 100%;
    padding: 12px 14px;
    background: #F5F5F5;
    border: 0px;
    margin-right: 0px;
  }

  .lba_box_content_mobil {
    display: block;
  }

  .title_lba_box_content_mobil {
    width: calc(100% - 30px);
    font-size: 22px;
    line-height: 28px;
    font-family: "Arial-Bold", sans-serif;
    margin-bottom: 20px;
  }

  .input_vlbc_data_mobil {
    height: 48px;
    width: 100%;
    padding: 12px 14px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    background: #F5F5F5;
    border: 0px;
  }

  .lba_info_adress_mobil {
    margin-top: 16px;
    display: flex;
    align-items: center;
  }

  .lba_info_adress_text_mobil {
    font-size: 14px;
    line-height: 20px;
    color: #8F8F8F;
  }

  .lba_info_adress_question_mobil {
    background: url(/assets/img/Subtract.png) no-repeat center;
    background-size: cover;
    width: 13px;
    height: 13px;
    margin-left: 10px;
  }

  .box_big_input_vlbc_mobil_low {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  .box_big_input_vlbc_mobil {
    justify-content: space-between;
    margin-bottom: 32px;
  }

  .input_vlbc_data_mobil_low {
    background: #F5F5F5;
    height: 48px;
    padding: 12px 14px;
    width: calc(50% - 5px);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    border: 0px;
  }

  .hidden_mobil {
    display: none;
  }

  .input_vlbc, .input_vlbc_contact_phone input {
    background: #F5F5F5;
    border: 0px;
    margin-right: 0px;
  }

  .lba_info_adress {
    margin-bottom: 24px;
    margin-top: 4px;
  }

  .box_mini_input_vlbc_mobil {
    display: block;
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .mobil_column .box_mini_input_vlbc {
    width: 100%;
    margin-bottom: 0;
  }

  .mobil_column .box_mini_input_vlbc.with_checkbox .input_vlbc_checkbox_text {
    margin-left: 0;
  }

  .mobil_column .box_mini_input_vlbc.with_checkbox {
    margin-bottom: 34px;
  }

  .box_mini_input_vlbc {
    width: calc(50% - 5px);
    margin-bottom: 10px;
    font-size: 16px;
    font-family: "Arial-Bold", sans-serif;
  }

  .box_mini_input_vlbc.with_checkbox {
    margin-bottom: 24px;
    font-family: "Arial-Regular", sans-serif;
  }

  .bmiv_mobil {
    display: block;
    padding: 3px 16px;
    background: #E6F6FF;
    border-radius: 5px;
    font-size: 16px;
    line-height: 22px;
    color: #00AAFF;
    margin-top: 10px;
  }

  .bmiv_mobil:hover {
    color: #ff6163;
  }

  .no_wrap_mobil {
    flex-wrap: inherit;
  }

  .mobil_big_title {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 20px;
    margin-top: 22px;
  }

  .mobil_big_title.hidden_mobil {
    display: none;
  }

  .mobil_big_title .remove {
    height: 16px;
  }

  .hidden_web.lba_box_content, .title_lba_box_content.hidden_web {
    display: block;
  }

  .lba_mini_title {
    margin-bottom: 16px;
  }

  .values_lba_box_btn {
    display: block;
    padding: 3px 16px;
    background: #E6F6FF;
    border-radius: 5px;
    font-size: 16px;
    line-height: 22px;
    color: #00AAFF;
  }

  .values_lba_box_contacts {
    width: 100%;
  }

  .input_vlbc_name {
    background: #F5F5F5;
    border: 0px;
    margin-right: 0px;
  }

  .input_vlbc_phone input {
    width: 100%;
    background: #F5F5F5;
    border: 0px;
    margin-right: 0px;
  }

  .values_lba_box_info {
    width: 100%;
  }

  .values_lba_box_textarea {
    background: #F5F5F5;
    border: 0px;
    margin-right: 0px;
  }

  .left_block_application_footer {
    display: none;
  }

  .left_block_application_footer_mobil {
    margin-bottom: 40px;
    height: fit-content;
    display: inline-block;
    float: right;
    order: 5;
    font-size: 13px;
    line-height: 16px;
    color: #858585;
  }

  .left_block_application_footer_mobil a {
    color: inherit;
  }

  .application {
    padding: 0px;
    margin-top: 32px;
    position: relative;
    overflow-x: hidden;
  }

  .preview_background_content {
    width: calc(100% - 30px);
    padding: 16px 0;
  }

  .right_preview {
    display: none;
  }

  .left_preview_items {
    display: none;
  }

  .advantages_content {
    width: calc(100% - 30px);
    display: block;
  }

  .item_advantages {
    width: 100%;
    margin-bottom: 56px;
  }

  .advantages_mobil {
    width: calc(100% - 78px);
    padding: 24px;
    background: #F7F8FA;
    border-radius: 24px;
    margin: 0px auto;
    color: black;
    display: block;
  }

  .question {
    padding: 35px 0 72px;
  }

  .question_content {
    width: calc(100% - 30px);
  }

  .footer_content {
    width: calc(100% - 30px);
  }

  .advantages_mobil_icon {
    width: 56px;
    height: 40px;
    background: #fc921f26;
    color: #FC921F;
    border-radius: 6px;
    top: 50%;
    line-height: 1.7;
    font-size: 24px;
    text-align: center;
    margin-bottom: 16px;
  }

  .advantages_mobil_prices {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 24px;
    line-height: 28px;
    color: #323C5D;
    margin-bottom: 8px;
  }

  .advantages_mobil_text {
    font-family: "OpenSans-Regular", sans-serif;
    font-size: 15px;
    line-height: 20px;
    color: rgba(50, 60, 93, 0.85);
    margin-bottom: 16px;
  }

  .advantages_mobil_href {
    font-size: 16px;
    line-height: 24px;
    color: #00AAFF;
    text-decoration: none;
  }

  .question_title {
    font-size: 32px;
    line-height: 38px;
    text-align: start;
  }

  .question_mini_title {
    font-size: 14px;
    line-height: 20px;
    text-align: start;
    margin-bottom: 30px;
  }

  .item_advantages:not(:last-schild) {
    margin-bottom: 64px;
  }

  .advantages {
    padding: 56px 0 0;
  }

  .footer {
    display: none;
  }

  .mobil_footer {
    padding: 10px 0;
    background: #eeeeee;
    display: block;
  }

  .mobil_footer_mini_content {
    width: calc(100% - 30px);
    margin: 0px auto;
  }

  .mobil_top_mini_footer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .mobil_top_mini_footer_menu {
    font-size: 12px;
    font-family: "Arial-Regular", sans-serif;
    font-weight: 400;
    line-height: 16px;
    margin: 0 5px;
    padding: 5px 0;
    color: #0af;
    display: inline-block;
    line-height: 16px;
  }

  .mobil_top_mini_footer_copir {
    color: #bdbdbd;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    margin-top: 32px;
  }

  .mobil_bottom_mini_footer {
    display: flex;
    justify-content: center;
  }

  .item_mobil_bottom_mini_footer {
    color: #0af;
    font-size: 12px;
    margin: 0 5px;
    padding: 5px 0;
    padding-top: 12px;
  }

  .logo_block {
    display: flex;
    align-items: center;
  }

  .bottom_header_content_mobil2 {
    display: block;
    background: url(/assets/img/Hamburger.png) no-repeat center;
    background-size: cover;
    width: 18px;
    height: 14px;
    margin-right: 27px;
  }

  .logo_box {
    width: 72px;
    height: 20px;
  }

  .left_preview_title {
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    margin: 0px auto;
    margin-bottom: 20px;
  }

  .left_preview_btn_mobil {
    background: #00AAFF;
    border-radius: 5px;
    padding: 12px 16px;
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 15px;
    display: block;
    height: fit-content;
    width: 250px;
    margin: 0px auto;
  }

  .left_preview_btn_mobil:hover {
    background: #009CF0;
  }

  .preview_background {
    height: 90vh;
  }

  .left_preview_mini_title {
    margin-bottom: 30px;
    text-align: center;
    margin: 0px auto;
    margin-bottom: 20px;
  }

  .bottom_preview {
    margin-top: 25px;
  }

  .checkbox_big_input_vlbc {
    margin-bottom: 0;
    margin-top: 10px;
  }

  .checkbox_input_vlbc {
    margin-bottom: 0;
  }

  .success_content {
    width: calc(100% - 30px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: auto;
    padding: 50px 0;
  }

  .left_success_content_text {
    display: none;
  }

  .left_success_content {
    order: 2;
    margin-top: 0;
    width: 100%;
  }

  .right_success_content {
    order: 1;
    margin-top: 62px;
  }

  .right_success_content_photo {
    width: 370px;
    height: 370px;
    margin: 0px auto;
  }

  .right_success_content {
    width: 100%;
    margin-top: 0;
  }

  .left_success_content_title {
    text-align: center;
    margin-bottom: 0;
  }

  .left_success_content_title {
    display: none;

  }

  .left_success_content_title_mobil {
    font-size: 32px;
    font-family: "Arial-Bold", sans-serif;
    font-weight: 700;
    line-height: 46px;
    display: block;
    text-align: center;
  }

  .left_success_content_mini_title {
    display: none;
  }

  .left_success_content_mini_title_mobil {
    font-size: 16px;
    font-family: "Arial-Regular", sans-serif;
    font-weight: 400;
    line-height: 24px;
    display: block;
    text-align: center;
    margin-top: 12px;
  }

  .left_success_content_btn {
    width: fit-content;
    text-align: center;
    margin: 40px auto 30px;
  }

  .box_big_item_vlbc_btn_mobil {
    display: block;
    width: 100%;
    order: 5;
    padding: 3px 16px;
    text-align: center;
    background: #E6F6FF;
    border-radius: 5px;
    font-size: 16px;
    line-height: 22px;
    color: #00AAFF;
    margin-top: 12px;
  }

  .item_bbbiv_photo {
    /*width: 220px;*/
    /*height: 120px;*/
    order: 1;
  }

  .tbbiv_left {
    order: 2;
    margin-top: 12px;
  }

  .tbbiv_right {
    order: 3;
    width: calc(50% - 15px);
    margin: 11px 0 1px;
  }

  .item_tbbiv_icon {
    display: none;
  }

  .item_tbbiv_checkbox {
    display: none;
  }

  .item_tbbiv_left {
    padding: 0 10px;
    border: 0;
    border-radius: 0;
  }

  .item_tbbiv_right {
    padding: 0 5px;
    border: 0;
    border-radius: 0;
  }

  .item_bbbiv_info {
    order: 4;
    margin-top: 4px;
  }

  .tbbiv_icon1 {
    display: none;
  }

  .tbbiv_icon2 {
    display: none;
  }

  .item_bbbiv_info_box {
    display: none;
  }

  .item_bbbiv_info_box_mobil {
    display: block;
  }

  .box_big_item_vlbc {
    width: 220px;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05), 0px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    margin-right: 10px;
  }

  .item_bbbiv_info_box_mobil {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    color: #8F8F8F;
    margin-bottom: 12px;
  }

  .item_bbbiv_info_box_mobil_prices {
    font-size: 22px;
    line-height: 18px;
    font-family: "Arial-Bold";
  }

  .tier_cost_container {
    min-height: 40px;
  }

  .cost_info {
    margin-top: 2px;
    display: block;
  }

  .item_bbbivib_mobil_prices_span {
    font-size: 15px;
    line-height: 20px;
    color: #8F8F8F;
    font-weight: 400;
    margin: 12px 0;
  }

  .item_bbbiv_info_name {
    margin-bottom: 12px;
    min-height: 76px;
  }

  .item_tbbiv_left_text {
    font-size: 16px;
    line-height: 23px;
    color: #8F8F8F;
  }

  .values_lba_box_content {
    overflow-x: visible;
    width: 100%;
  }

  .values_lba_box_content.cargo_block {
    flex-direction: column;
    align-items: baseline;
  }

  .item_rbai {
    display: block;
  }

  .item_rbai_text {
    margin-top: 8px;
  }

  .preview_background {
    height: fit-content;
  }

  .left_preview {
    margin-top: 0px;
  }

  .preview_background {
    min-height: 400px;
  }

  .preview {
    margin-top: 65px;
  }

  .margin_block {
    margin-top: 10px;
  }

  .item_bbcv_box {
    width: calc(100% - 10px);
  }

  .input_vlbc,
  .input_vlbc_contact_phone input,
  .vlbc_address .autocomplete-container .input-container input {
    width: 100%;
    height: 48px;
    border: none;
    padding: 12px 14px;
    border-radius: 4px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    background-color: #F5F5F5;
  }

  .vlbc_address .autocomplete-container {
    height: 48px;
  }

  .title_lba_box_content.removable .confirm {
    order: 0;
  }

  .title_lba_box_content.removable img.remove {
    order: 1;
  }
}

@media (max-width: 400px) {

  .item_bbbiv_photo {
    height: 135px;
    background-size:contain;
  }

  .item_tbbiv_left_text {
    font-size: 16px;
    white-space: nowrap;
  }

  .preview {
    margin-top: 55px;
  }

  .bottom_preview_icon {
    width: 78px;
    height: 8px;
  }

  .bottom_preview {
    margin-top: 0px;
  }

  .bottom_preview_text {
    font-size: 14px;
  }

  .box_mini_input_vlbc {
    width: 100%;
  }

  .box_mini_input_vlbc.with_checkbox {
    margin-top: -8px;
    margin-bottom: 24px;
  }

  .input_vlbc_checkbox + label {
    align-items: flex-start;
    margin-left: 0;
  }

  .right_success_content_photo {
    width: 250px;
    height: 250px;
  }

  .left_preview_mini_title {
    font-size: 16px;
  }

  .left_preview_title {
    font-size: 29px;
  }

}

.support-chat-launcher {
  cursor: pointer;
  border-bottom: 1px dashed #8F8F8F;
}

a.phone {
  color: #8F8F8F;
  border-bottom: 1px dashed #8F8F8F;
  text-decoration: none;
}

.errors .error {
  color: red;
}

.success {
  color: #63b463;
}

.shake-horizontal {
  animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-1-28 13:49:40
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
@keyframes shake-horizontal {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(8px);
  }
  90% {
    transform: translateX(-8px);
  }
}

.slide-out-right {
  -webkit-animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-5-11 17:22:14
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
    opacity: 0;
  }
}
@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
    opacity: 0;
  }
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-5-11 18:9:8
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/* JivoSite */
body > jdiv {
  display: none;
}

body > jdiv.visible {
  display: block;
}
