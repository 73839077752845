@import "loader";

.ng-autocomplete {
  width: 100% !important;
}

.ng-autocomplete .suggestions-container {
  z-index: 10;
}

.not-visible {
  visibility: hidden;
}
